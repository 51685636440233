<template>
    <div class="relative">
        <custom-loader :show="showLoader">
            <div class="py-1 d-flex justify-content-between">
                <div>
                    <b-button-group style="height: 38px">
                        <b-button @click="reloadData" class="px-1 p-0" variant="primary" style="border-bottom-left-radius: 20px; border-top-left-radius: 20px;">
                            <feather-icon class="large-icon" icon="RefreshCwIcon"/>
                        </b-button>
                        <b-button @click="clearFilters" class="px-1 p-0" variant="primary" style="border-bottom-right-radius: 20px; border-top-right-radius: 20px;">
                            <img style="height: 25px; margin:0;" src="@/assets/images/icons/clearFilter.svg" alt="Refresh icon" />
                        </b-button>
                    </b-button-group></div>
                <div><slot name="buttons"></slot></div>

            </div>
            <b-table :id="getTableName()" :ref="'customTable_' + getTableName()" class="customTable text-center" thead-class="header-top" sticky-header show-empty select-mode="single" style="user-select: none; max-height: 100% !important ; height: 100%;" :items="items" :fields="fields">
                <template #head()="scope">
                    <div style="getMinWidth(scope.field.min_width, scope.field.max_width)" class="d-flex justify-content-center w-100 ">
                        <div>
                            <div class="textTableHeader" :style="getMinWidth(scope.field.min_width, scope.field.max_width)" style="text-transform: none;margin-bottom: 10px; font-size: 13px;" :class="!scope.field.filter || !(scope.field.permissions || scope.field.permissions === '') ? 'align-top' : ''">
                                {{ scope.field.label }}
                            </div>

                            <div class="filter-col" v-if="scope.field.filter && (scope.field.permissions || scope.field.permissions === '')">
                                <div v-if="scope.field.filter_type === 'input'">
                                    <b-form-input style="height: 30px;" debounce="300" class="customInputFieldTable"  @update="loadData" @focus="focus=true" v-model="filters[scope.column]"/>
                                </div>

                                <div v-else-if="scope.field.filter_type === 'date'">
                                    <date-picker :first-day-of-week="2" :model-config="modelConfig" :popover="popoverOptions" @input="loadData" popover-direction="top" class="relative w-100" v-model="filters[scope.column]" is-range :masks="{input: ['DD. MM. YYYY'], data: ['DD. MM. YYYY']}">
                                        <template #default="{ inputValue, inputEvents }">
                                            <div class="flex justify-center items-center">
                                                <b-input-group>
                                                    <b-form-input class="not-disabled customDatePickerTable customDatePicker" :value="inputValue.start !== null ? `${inputValue.start} - ${inputValue.end}` : ''" v-on="inputEvents.start" @click="loadData" disabled />
                                                </b-input-group>
                                            </div>
                                        </template>
                                    </date-picker>
                                </div>

                                <div v-else-if="scope.field.filter_type === 'select'" >
                                    <v-select v-model="filters[scope.column]" @input="loadData" appendToBody class="floating-filter w-100" :options="scope.field.filterParams" :searchable=true :label="scope.field.filterParamsName" :reduce="scope.field.reduce">
                                        <template #selected-option="{name}">
                                            <span  class="textCutSelect">
                                                {{ name }}
                                            </span>
                                        </template>
                                    </v-select>
                                </div>
                            </div>
                        </div>
                    </div>

                </template>

                <template #empty>
                    <slot name="empty">
                        <div>
                            {{ $t('table_fields.no_data') }}.
                        </div>
                    </slot>
                </template>

                <template #cell()="item">
                    <div role="item" :aria-rowindex="item.index" class="d-flex justify-content-center column" style="font-size: 13px;" :id="item.field.key">
                        <slot :name="item.field.key" v-bind="item">
                            <div v-if="showEditing !== item.index + '-' + item.field.key || !item.field.editable" :style="getMinWidth(item.field.min_width, item.field.max_width)">
                                <span v-if="item.field.formatter" class="textCut" >
                                    {{item.field.formatter(item.item[item.field.key])}}
                                </span>
                                <span v-else-if="item.field.formatterItem" class="textCut">
                                    {{item.field.formatterItem(item.item)}}
                                </span>
                                <span class="textCut" v-else>
                                    {{getItem(item.item, item.field.key)}}
                                </span>
                            </div>
                            <div style="width: 90%; min-width: 50px; height: 25px;" v-else>
                                <b-form-input @blur="inputChanged(item)" v-model="enteredText" v-if='!item.field.editableType || item.field.editableType === "input"' style="height: 25px"></b-form-input>
                                <v-select v-model="selectedItem" @input="selectChanged(item)" appendToBody style="width: 100%; height: 25px; top: -3px;" v-if='item.field.editableType === "select"' :label="item.field.filterParamsName" :options="item.field.filterParams"></v-select>
                            </div>
                        </slot>
                    </div>
                </template>
            </b-table>


            <b-row>
                <b-col cols="12" md="6" >
                    <div class="d-flex d-md-block justify-content-center">
                        <span v-if="items.length > 0 && pagination" class="d-flex align-items-center">
                            <div>
                                {{ ((pagination.current_page -1) * pagination.per_page) + 1 }} -
                                <span v-if="pagination.current_page * pagination.per_page < pagination.total_items">
                                    {{ pagination.current_page * pagination.per_page}}
                                </span>
                                <span v-else>
                                    {{pagination.total_items}}
                                </span>
                                {{ $t('pagination.of') }} {{ pagination.total_items }}
                            </div>
                            <div >
                                <v-select class="ml-1 pageSelect" :options="pageLengths" v-model="pagination.per_page" :clearable="false" appendToBody @input="changePerPage" style="width: 140px;">
                                    <template #selected-option="{label}">
                                        <span style="font-size: 12px; margin-bottom: 10px;" class="d-flex align-items-center">
                                            <span>
                                                {{ label }} {{$t('pagination.per_page')}}
                                            </span>
                                        </span>
                                    </template>
                                </v-select>
                            </div>
                        </span>
                    </div>
                </b-col>
                <b-col cols="12" md="6" class="d-flex justify-content-center justify-content-md-end">
                    <b-pagination class="mt-2 mt-md-0" v-model="pagination.current_page" @input="loadData" pills :total-rows="pagination.total_items" :per-page="pagination.per_page" size="md" align="center" aria-controls="reversalBillsTable" last-number first-number :disabled="loadingActive">
                    </b-pagination>
                </b-col>
            </b-row>
        </custom-loader>
    </div>
</template>
<script>

    import {BTable, BFormInput, BPagination, BInputGroup, BButton, BButtonGroup, BRow, BCol} from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import 'vue-select/dist/vue-select.css'
    import * as Sentry from '@sentry/vue'
    import {DatePicker} from 'v-calendar'
    import CustomLoader from '@/components/CustomLoader.vue'

    export default {
        components: {
            CustomLoader,
            BButton,
            BRow,
            BCol,
            BButtonGroup,
            BTable,
            BInputGroup,
            BFormInput,
            BPagination,
            vSelect,
            DatePicker
        },
        props: {
            fields: {
                type: Array
            },
            apiUrl: {
                type: String
            },
            name: {
                type: String
            },
            additional_filters: {
                type: Object
            }
        },
        data() {
            return {
                showLoader: true,
                pageLengths: [10, 20, 50, 100],
                modelConfig: {
                    type: 'string',
                    mask: 'iso'
                },
                popoverOptions: {
                    positionFixed: true
                },
                timestamp: 'bill_code',
                pagination: {
                    per_page: 10,
                    current_page: 1,
                    total_pages: 0,
                    total_items: 0
                },
                filters: {},
                items: [],
                loadingActive: false,
                lastClickOnTable: new Date(),
                lastClickCheck: new Date(),
                showEditing: '',
                selectedRow: -1,
                selectedCol: -1,
                enteredText: '',
                selectedItem: null
            }
        },
        methods: {
            async reloadData() {
                if (this.pagination) {
                    this.pagination.current_page = 1
                }
                await this.loadData()
            },
            clearFilters() {
                this.filters = {}
                this.loadData()
            },
            changePerPage() {
                this.pagination.current_page = 1
                this.loadData()
            },
            inputChanged(item) {
                if (!this.enteredText) {
                    return
                }

                const id = item.field.editReturnParam ?? 'id'
                let returnItem = ''

                if (this.selectedRow >= 0 && this.selectedRow < this.items.length) {
                    returnItem = this.items[this.selectedRow][id]
                }

                let col_id = ''

                if (this.selectedCol >= 0 && this.selectedCol < this.fields.length) {
                    const colKey = this.fields[this.selectedCol].key

                    if (colKey) {
                        col_id = colKey
                    }
                }

                const payload = {
                    value: this.enteredText,
                    item_id: returnItem,
                    col_id,
                    rowIndex: this.selectedRow,
                    colIndex: this.selectedCol
                }

                this.$emit('edit', payload)

                this.showEditing = ''
                this.selectedRow = -1
                this.selectedCol =  -1
                this.enteredText = ''
                this.selectedItem = null
            },
            selectChanged(item) {
                const id = item.field.editReturnParam ?? 'id'
                let returnItem = ''

                if (this.selectedRow >= 0 && this.selectedRow < this.items.length) {
                    returnItem = this.items[this.selectedRow][id]
                }

                let item_id = ''

                if (this.selectedItem) {
                    item_id = this.selectedItem[id]
                }


                let col_id = ''

                if (this.selectedCol >= 0 && this.selectedCol < this.fields.length) {
                    const colKey = this.fields[this.selectedCol].key

                    if (colKey) {
                        col_id = colKey
                    }
                }


                const payload = {
                    value: item_id,
                    item_id: returnItem,
                    col_id,
                    rowIndex: this.selectedRow,
                    colIndex: this.selectedCol
                }

                this.$emit('edit', payload)

                this.showEditing = ''
                this.selectedRow = -1
                this.selectedCol =  -1
                this.enteredText = ''
                this.selectedItem = null
            },
            getMinWidth(minWidth, maxWidth) {
                let text = 'text-overflow: ellipsis; white-space: nowrap;  overflow: hidden; text-align: center; width: 100% !important; '

                if (minWidth) {
                    text += `min-width: ${  minWidth  }px;`
                } else {
                    text += 'min-width: 100px;'
                }

                if (maxWidth) {
                    text += `max-width: ${  maxWidth  }px !important;`
                }


                return text
            },
            getItem(item, key) {
                const value = key.split('.').reduce((p, c) => (p && p[c]) || null, item)

                if (!value) {
                    return '/'
                }

                return  value
            },
            getFilterFormatter(key) {
                for (const item of this.fields) {
                    if (item.key === key) {
                        if (item.filterFormatter) {
                            return item.filterFormatter
                        }
                        break
                    }
                }

                return null
            },
            getFilters() {
                let filter = ''

                for (const key of Object.keys(this.filters)) {

                    let value = this.filters[key]

                    if (value === null || value === undefined || value === '') {
                        continue
                    }

                    const item = this.fields.find(ele => ele.key === key)


                    if (item.filter_type === 'date') {
                        const newEndDate = this.$dayjs(value.end).hour(23).minute(59).second(59).toISOString()
                        const newStartDate = this.$dayjs(value.start).hour(0).minute(0).second(0).toISOString()

                        filter += `&${  key  }.gte=${ newStartDate  }&`
                        filter += `&${  key  }.lte=${ newEndDate  }&`

                        continue                    
                    }

                    const filterFormatter = this.getFilterFormatter(key)

                    if (filterFormatter) {
                        value = filterFormatter(this.filters[key])
                    }


                    if (item && item.filterKey) {
                        filter += `&${  item.filterKey  }=${ value  }&`
                    } else {
                        filter += `&${  key  }=${ value  }&`
                    }
                }

                if (this.additional_filters) {
                    for (const key of Object.keys(this.additional_filters)) {
                        const item = this.additional_filters[key]

                        if (item === null || item === undefined || item === '') {
                            continue
                        }

                        if (item.type === 'date') {
                            filter += `&${  key  }.gte=${ item.value.start  }&`
                            filter += `&${  key  }.lte=${ item.value.end  }&`
                            continue
                        }
                    }
                }
                return filter
            },
            async loadData() {
                if (this.loadingActive) {
                    return
                }

                try {
                    this.loadingActive = true
                    this.setTableFiltersToURL()

                    this.showLoader = true

                    const filters = this.getFilters()

                    const response = await this.$http.get(`${this.apiUrl  }?page=${  this.pagination.current_page  }&perPage=${  this.pagination.per_page}${  filters}`)
                    this.items = response.data.items ?? []
                    this.pagination = response.data.pagination

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.loadingActive = false
                    this.showLoader = false
                }
            },
            getTableName() {
                if (this.name) return `t.${this.name}`
                return 't'
            },
            setTableFiltersToURL() {
                const payloadData = {
                    filters: this.filters,
                    per_page: this.pagination.per_page,
                    current_page: this.pagination.current_page
                }

                const payload = Buffer.from(JSON.stringify(payloadData)).toString('base64')
                let payloadOld = ''
                if (this.$route.query && this.$route.query[this.getTableName()]) {
                    payloadOld = this.$route.query[this.getTableName()]
                }

                const type = (typeof (payloadOld))
                if (type === 'string' && payload !== payloadOld) {
                    this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, [this.getTableName()]: payload } })
                } else if (type === 'object' && payload !== payloadOld[0]) {
                    this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, [this.getTableName()]: payload } })
                }
            },
            readTableFiltersFromURL() {
                if (!this.$route.query || !this.$route.query[this.getTableName()]) return
                // get b64 date from query parameter and parse it
                const payload = this.$route.query[this.getTableName()]
                const data = JSON.parse(atob(Buffer.from(payload).toString()))
                this.filters = data.filters
                this.pagination.per_page = data.per_page
                this.pagination.current_page = data.current_page
            },
            getIndexes(evnt) {
                const column = evnt.target.closest('td')

                let colIndex = -1
                let rowIndex = -1

                if (column) {
                    const tmp = column.getAttribute('aria-colindex')

                    if (tmp) {
                        colIndex = parseInt(tmp) - 1
                    }
                }

                const item = evnt.target.closest('td div[role="item"]')

                if (item) {
                    const tmp = item.getAttribute('aria-rowindex')

                    if (tmp) {
                        rowIndex = parseInt(tmp)
                    }
                }

                return {rowIndex, colIndex}
            },
            handleClick(evnt) {

                const newTime = new Date()

                if (newTime - this.lastClickOnTable <= 500) {
                    const {rowIndex, colIndex} = this.getIndexes(evnt)

                    if (rowIndex >= 0 && colIndex >= 0) {

                        const colKey = this.fields[colIndex].key

                        this.showEditing = `${rowIndex  }-${  colKey}`
                        this.selectedCol = colIndex
                        this.selectedRow = rowIndex

                        // const selectedId = this.items[rowIndex][this.fields[colIndex].key]
                        // this.selectedItem = this.fields[colIndex].filterParams.find(ele => ele.id === selectedId)
                    }
                }

                if (this.showEditing) {
                    const {rowIndex, colIndex} = this.getIndexes(evnt)

                    if (!(rowIndex >= 0 && colIndex >= 0)) {
                        this.showEditing = ''
                        this.selectedCol = -1
                        this.selectedRow = -1
                        return
                    }


                    const colKey = this.fields[colIndex].key
                    const tmp   = `${rowIndex  }-${  colKey}`

                    if (tmp !== this.showEditing) {
                        this.showEditing = ''
                        this.selectedCol = -1
                        this.selectedRow = -1
                        return
                    }
                }

                this.lastClickOnTable = newTime
            },
            handleClickWindow(evnt) {

                const searchString = `#${  this.getTableName() }`
                const item = evnt.target.closest(searchString)
                const newTime = new Date()


                if (!item && newTime - this.lastClickCheck >= 700) {
                    this.showEditing = ''
                    return
                }

                this.lastClickCheck = newTime
            }
        },

        async mounted() {
            await this.readTableFiltersFromURL()
            await this.loadData()
            if (this.$refs[`customTable_${  this.getTableName()}`]) {
                const customTable = this.$refs[`customTable_${  this.getTableName()}`].$el
                customTable.addEventListener('click', this.handleClick)
            }

            window.addEventListener('click', this.handleClickWindow)
        },
        beforeDestroy() {
            window.removeEventListener('click', this.handleClickWindow)

            if (this.$refs[`customTable_${  this.getTableName()}`]) {
                const customTable = this.$refs[`customTable_${  this.getTableName()}`].$el
                customTable.removeEventListener('click', this.handleClick)
            }
        }
    }

</script>

<style>

  .customTable .b-table tr:last-child td:last-child{
    border-bottom-right-radius: 10px !important;
  }

  .customTable .b-table tr:last-child td:first-child{
    border-bottom-left-radius: 10px !important;
  }

  .customTable .b-table tr:first-child th:last-child{
    border-top-right-radius: 10px !important;
  }

  .customTable .b-table tr:first-child th:first-child{
    border-top-left-radius: 10px !important;
  }

  .customTable .b-table td{
    padding: 10px 5px !important;
  }

  .customInputFieldTable:focus{
    outline: none !important;
    border-color: transparent;
  }

  .customTable.b-table-sticky-header{
    border-radius: 10px !important;
  }

  .customTable .vs__dropdown-toggle{
    height: 30px !important;
  }

  .customTable .vs__search{
    width: 100% !important;
  }

  .pageSelect .vs__dropdown-toggle{
    height: 30px !important;
  }

  .customTable .vs__search{
    position: absolute !important;
  }
</style>

<style scoped>
.customDatePicker{
  height: 30px !important;
}

  .floating-filter{
    width: 100%;
    height: 30px !important;
  }

  .customTable .textCutSelect{
    width: calc(100%) !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .customTable .textCut{
    width: calc(100% - 30px) !important;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
.large-icon {
  width: 18px;  /* Adjust width as needed */
  height: 18px; /* Adjust height as needed */
}

</style>